import {
	Menu,
	Alert,
	MenuItem,
	Snackbar,
	Typography,
	IconButton,
	InputLabel,
	FormControl,
	OutlinedInput,
	InputAdornment,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import MenuIcon from '@mui/icons-material/Menu'
import { useTheme } from '@mui/material/styles'
import LoginIcon from '@mui/icons-material/Login'
import Visibility from '@mui/icons-material/Visibility'
import useMediaQuery from '@mui/material/useMediaQuery'
import AuthService from '../../api-services/auth-service'
import TranslateIcon from '@mui/icons-material/Translate'
import { Link, Redirect, useHistory } from 'react-router-dom'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const Login = () => {
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [language, setLanguage] = useState('en')
	const [alertOpen, setAlertOpen] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [loginForm, setLoginForm] = useState({ email: '', password: '' })

	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('sm'))

	const history = useHistory()
	const { t, i18n } = useTranslation()

	const open = Boolean(anchorEl)

	const handleOnChange = e => {
		const { name, value } = e.target

		setLoginForm(prevUserData => ({
			...prevUserData,
			[name]: value,
		}))
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleLangChange = lang => {
		setLanguage(lang)

		i18n.changeLanguage(lang)
	}

	const handleLogin = async e => {
		e.preventDefault()

		// console.log(history)

		setLoading(true)

		const loginFormData = new FormData()
		loginFormData.append('email', loginForm.email)
		loginFormData.append('password', loginForm.password)

		const response = await AuthService.login(loginFormData)

		if (!response.error) {
			setLoading(false)
			if (response.role_name === 'admin') {
				if (response.auth_enabled) history.push('/auth-2fa')
				else {
					localStorage.setItem('authenticated', true)
					history.push('/admin-dashboard')
				}
			} else {
				if (response.plan_activated) {
					if (response.auth_enabled) history.push('/auth-2fa')
					else {
						localStorage.setItem('authenticated', true)

						if (history.length <= 4 && localStorage.getItem('id') !== null)
							history.replace(`/${localStorage.getItem('id')}`)
						else if (localStorage.getItem('authenticated'))
							if (localStorage.getItem('role') === 'user')
								history.push('/dashboard')
					}
				} else if (response.trial) {
					if (response.auth_enabled) history.push('/auth-2fa')
					else {
						localStorage.setItem('authenticated', true)

						if (history.length <= 4 && localStorage.getItem('id') !== null) {
							history.replace(`/${localStorage.getItem('id')}`)
						} else {
							localStorage.setItem('authenticated', true)
							history.push('/dashboard')
						}
					}
				} else {
					if (response.auth_enabled) history.push('/auth-2fa')
					else {
						localStorage.setItem('authenticated', true)
						history.push('/plans')
					}
				}
			}
		} else if (response.error) {
			setLoading(false)
			setError(response.msg)
			setAlertOpen(true)
		} else if (response === undefined) setLoading(false)
	}

	const handleAlertClose = () => {
		setAlertOpen(false)
	}

	return (
		<>
			{localStorage.getItem('authenticated') === 'true' ? (
				<Redirect to='/dashboard' />
			) : (
				<Box
					sx={{
						marginLeft: { lg: '13%', md: '20%', sm: '20%', xs: '5%' },
						marginRight: { lg: '13%', md: '20%', sm: '20%', xs: '5%' },
					}}
				>
					<Box
						sx={{
							display: 'flex',
							marginTop: '7%',
							flexDirection: {
								lg: 'row',
								md: 'column',
								sm: 'column',
								xs: 'column',
							},
							alignItems: 'center',
							borderRadius: '15px',
							height: { lg: '80vh', md: '80vh', sm: '80vh', xs: '80vh' },
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								background: '#b3e6ff',
								borderTopRightRadius: {
									md: '12px',
									lg: 0,
									sm: '12px',
									xs: '12px',
								},
								justifyContent: 'center',
								borderTopLeftRadius: '12px',
								borderBottomLeftRadius: { lg: '12px' },
								height: { lg: '100%', md: '35%', sm: '25%', xs: '25%' },
								width: { lg: '55%', md: '100%', sm: '100%', xs: '100%' },
							}}
						>
							<a
								target='_blank'
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
								href='https://identitysafe.de'
							>
								<img src='/primaryIconLight.png' width='90%' />
							</a>
						</Box>
						<Box
							sx={{
								background: '#e6e6e6',
								borderBottomLeftRadius: {
									lg: 0,
									md: '12px',
									sm: '12px',
									xs: '12px',
								},
								borderBottomRightRadius: '12px',
								height: { lg: '100%', md: '70%' },
								borderTopRightRadius: { lg: '12px' },
								width: { lg: '47%', md: '100%', sm: '100%', xs: '100%' },
							}}
						>
							<Box
								sx={{
									float: 'right',
								}}
							>
								<IconButton
									color='inherit'
									id='long-button'
									aria-label='more'
									aria-haspopup='true'
									onClick={handleClick}
									aria-expanded={open ? 'true' : undefined}
									aria-controls={open ? 'long-menu' : undefined}
								>
									<TranslateIcon />
								</IconButton>
								<Menu
									open={open}
									id='long-menu'
									anchorEl={anchorEl}
									onClose={handleClose}
									PaperProps={{
										style: {
											width: '10ch',
											maxHeight: 48 * 4.5,
										},
									}}
									MenuListProps={{
										'aria-labelledby': 'long-button',
									}}
								>
									<MenuItem onClick={() => handleLangChange('en')}>
										{t('en')}
									</MenuItem>
									<MenuItem onClick={() => handleLangChange('de')}>
										{t('de')}
									</MenuItem>
								</Menu>
							</Box>
							<Box
								sx={{
									mx: 8,
									height: '70%',
									marginTop: { lg: '15%' },
								}}
							>
								<Box>
									<Typography
										display='block'
										variant='button'
										sx={{ fontSize: '30px' }}
									>
										{t('login')}
									</Typography>
								</Box>
								<Box>
									<Typography
										sx={{ fontSize: '12px' }}
										variant='overline'
										display='block'
									>
										{t('welcomeBack')} <br />
									</Typography>
								</Box>
								<Box
									sx={{
										mt: 5,
										height: '70%',
									}}
								>
									<form
										style={{ height: '100%', marginBottom: '20%' }}
										onSubmit={handleLogin}
									>
										<Box
											sx={{
												textAlign: 'center',
											}}
										>
											<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
												<InputLabel htmlFor='outlined-adornment-email'>
													{t('email')}
												</InputLabel>
												<OutlinedInput
													type='email'
													name='email'
													label={t('email')}
													variant='outlined'
													onChange={handleOnChange}
													id='outlined-adornment-email'
												/>
											</FormControl>
										</Box>

										<Box
											sx={{
												textAlign: 'center',
											}}
										>
											<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
												<InputLabel htmlFor='outlined-adornment-password'>
													{t('password')}
												</InputLabel>
												<OutlinedInput
													id='outlined-adornment-password'
													type={showPassword ? 'text' : 'password'}
													name='password'
													onChange={handleOnChange}
													endAdornment={
														<InputAdornment position='end'>
															<IconButton
																aria-label='toggle password visibility'
																onClick={() => setShowPassword(!showPassword)}
																onMouseDown={e => e.preventDefault()}
																edge='end'
															>
																{showPassword ? (
																	<VisibilityOff />
																) : (
																	<Visibility />
																)}
															</IconButton>
														</InputAdornment>
													}
													label={t('password')}
												/>
											</FormControl>
										</Box>

										<Box
											sx={{
												textAlign: 'center',
												mt: 2,
											}}
										>
											<Typography
												variant='subtitle1'
												gutterBottom
												component='div'
											>
												{t('forgotPass')}
												{matches ? ' ' : <br />}
												<Link to={{ pathname: '/forget-password' }}>
													{t('clickHere')}
												</Link>
											</Typography>
											<Typography
												variant='subtitle1'
												gutterBottom
												component='div'
											>
												{t('noAccount')}
												{matches ? ' ' : <br />}
												<Link to={{ pathname: '/signup' }}>
													{t('createOne')}
												</Link>
											</Typography>
											<LoadingButton
												disabled={
													loginForm.email === '' || loginForm.password === ''
												}
												loading={loading}
												loadingPosition='end'
												type='submit'
												variant='contained'
												endIcon={<LoginIcon />}
											>
												{t('signin')}
											</LoadingButton>
										</Box>
									</form>
								</Box>
							</Box>
						</Box>
					</Box>
					<Snackbar
						open={alertOpen}
						autoHideDuration={2500}
						onClose={handleAlertClose}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					>
						<Alert
							severity='error'
							sx={{ width: '100%' }}
							onClose={handleAlertClose}
						>
							{error}
						</Alert>
					</Snackbar>
				</Box>
			)}
		</>
	)
}

export default Login
