import {
	Menu,
	Alert,
	Snackbar,
	MenuItem,
	IconButton,
	Typography,
	InputLabel,
	FormControl,
	OutlinedInput,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import LoginIcon from '@mui/icons-material/Login'
import TranslateIcon from '@mui/icons-material/Translate'
import AuthService from '../../api-services/auth-service'
import { Link, Redirect, useHistory } from 'react-router-dom'

const Signup = () => {
	const [error, setError] = useState('')
	const [success, setSuccess] = useState('')
	const [loading, setLoading] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [language, setLanguage] = useState('en')
	const [alertOpen, setAlertOpen] = useState(false)
	const [signupForm, setSignupForm] = useState({ email: '', fullname: '' })

	const history = useHistory()
	const { t, i18n } = useTranslation()

	const open = Boolean(anchorEl)

	const handleOnChange = e => {
		const { name, value } = e.target

		setSignupForm(prevUserData => ({
			...prevUserData,
			[name]: value,
		}))
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleLangChange = lang => {
		setLanguage(lang)

		i18n.changeLanguage(lang)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleSignup = async e => {
		e.preventDefault()

		setLoading(true)

		const signupFormData = new FormData()
		signupFormData.append('email', signupForm.email)
		signupFormData.append('fullname', signupForm.fullname)

		const response = await AuthService.register(signupFormData)

		if (!response.error) {
			setLoading(false)
			setSuccess(t('emailSentWithVerificationLink'))
			setAlertOpen(true)
			if (history.length === 2) {
				history.replace(`/${localStorage.getItem('id')}`)
			} else {
				if (localStorage.getItem('authenticated')) history.push('/login')
			}
		} else if (response.error) {
			setLoading(false)
			setError(response.msg)
			setAlertOpen(true)
		} else if (response === undefined) setLoading(false)
	}

	const handleAlertClose = () => {
		setError('')
		setSuccess('')
		setAlertOpen(false)
	}

	return (
		<>
			{localStorage.getItem('authenticated') ? (
				<Redirect to='/dashboard' />
			) : (
				<Box
					sx={{
						marginLeft: { lg: '13%', md: '20%', sm: '20%', xs: '5%' },
						marginRight: { lg: '13%', md: '20%', sm: '20%', xs: '5%' },
					}}
				>
					<Box
						sx={{
							display: 'flex',
							marginTop: '7%',
							flexDirection: {
								lg: 'row',
								md: 'column',
								sm: 'column',
								xs: 'column',
							},
							alignItems: 'center',
							borderRadius: '15px',
							height: { lg: '80vh', md: '80vh', sm: '80vh', xs: '80vh' },
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								background: '#b3e6ff',
								borderTopRightRadius: {
									md: '12px',
									lg: 0,
									sm: '12px',
									xs: '12px',
								},
								justifyContent: 'center',
								borderTopLeftRadius: '12px',
								borderBottomLeftRadius: { lg: '12px' },
								height: { lg: '100%', md: '35%', sm: '25%', xs: '25%' },
								width: { lg: '55%', md: '100%', sm: '100%', xs: '100%' },
							}}
						>
							<a
								target='_blank'
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
								href='https://identitysafe.de'
							>
								<img src='/primaryIconLight.png' width='90%' />
							</a>
						</Box>
						<Box
							sx={{
								background: '#e6e6e6',
								borderBottomRightRadius: '12px',
								height: { lg: '100%', md: '70%' },
								borderTopRightRadius: { lg: '12px' },
								width: { lg: '47%', md: '100%', sm: '100%', xs: '100%' },
								borderBottomLeftRadius: {
									md: '12px',
									lg: 0,
									sm: '12px',
									xs: '12px',
								},
							}}
						>
							<Box
								sx={{
									float: 'right',
								}}
							>
								<IconButton
									color='inherit'
									id='long-button'
									aria-label='more'
									aria-haspopup='true'
									onClick={handleClick}
									aria-expanded={open ? 'true' : undefined}
									aria-controls={open ? 'long-menu' : undefined}
								>
									<TranslateIcon />
								</IconButton>
								<Menu
									open={open}
									id='long-menu'
									anchorEl={anchorEl}
									onClose={handleClose}
									PaperProps={{
										style: {
											width: '10ch',
											maxHeight: 48 * 4.5,
										},
									}}
									MenuListProps={{
										'aria-labelledby': 'long-button',
									}}
								>
									<MenuItem onClick={() => handleLangChange('en')}>
										{t('en')}
									</MenuItem>
									<MenuItem onClick={() => handleLangChange('de')}>
										{t('de')}
									</MenuItem>
								</Menu>
							</Box>
							<Box
								sx={{
									mx: 8,
									height: '70%',
									marginTop: { lg: '25%' },
								}}
							>
								<Box>
									<Typography
										sx={{ fontSize: '30px' }}
										variant='button'
										display='block'
									>
										{t('signup')}
									</Typography>
								</Box>
								<Box>
									<Typography
										display='block'
										variant='overline'
										sx={{ fontSize: '15px', lineHeight: '1.4em' }}
									>
										{t('joinIdentitySafe')} <br />
									</Typography>
								</Box>
								<Box
									sx={{
										height: '70%',
										mt: 5,
									}}
								>
									<form
										style={{
											height: '100%',
											marginBottom: '20%',
										}}
										onSubmit={handleSignup}
									>
										<Box
											sx={{
												textAlign: 'center',
											}}
										>
											<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
												<InputLabel htmlFor='outlined-adornment-fullname'>
													{t('fullname')}
												</InputLabel>
												<OutlinedInput
													type='text'
													name='fullname'
													label={t('fullname')}
													variant='outlined'
													onChange={handleOnChange}
													id='outlined-adornment-fullname'
												/>
											</FormControl>
										</Box>

										<Box
											sx={{
												textAlign: 'center',
											}}
										>
											<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
												<InputLabel htmlFor='outlined-adornment-email'>
													{t('email')}
												</InputLabel>
												<OutlinedInput
													type='email'
													name='email'
													label={t('email')}
													variant='outlined'
													onChange={handleOnChange}
													id='outlined-adornment-email'
												/>
											</FormControl>
										</Box>

										<Box
											sx={{
												textAlign: 'center',
												mt: 2,
											}}
										>
											<Typography
												variant='subtitle1'
												gutterBottom
												component='div'
											>
												{t('alreadyHaveAccount')}{' '}
												<Link to={{ pathname: '/' }}>{t('login')}</Link>
											</Typography>
											<LoadingButton
												disabled={
													signupForm.email === '' || signupForm.fullname === ''
												}
												loading={loading}
												loadingPosition='end'
												type='submit'
												variant='contained'
												endIcon={<LoginIcon />}
											>
												{t('signupButton')}
											</LoadingButton>
										</Box>
									</form>
								</Box>
							</Box>
						</Box>
					</Box>
					<Snackbar
						open={alertOpen}
						autoHideDuration={6000}
						onClose={handleAlertClose}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					>
						<Alert
							onClose={handleAlertClose}
							severity={error === '' ? 'success' : 'error'}
							sx={{ width: '100%' }}
						>
							{error === '' ? success : error}
						</Alert>
					</Snackbar>
				</Box>
			)}
		</>
	)
}

export default Signup
