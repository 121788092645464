import {
	List,
	ListItem,
	Snackbar,
	Typography,
	IconButton,
	InputLabel,
	FormControl,
	ListItemText,
	OutlinedInput,
	InputAdornment,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Visibility from '@mui/icons-material/Visibility'
import AuthService from '../../api-services/auth-service'
import SnackBar from '../../components/snackbar/SnackBar'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'

const ChangePassword = () => {
	const { t, i18n } = useTranslation()

	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [errorAlertOpen, setErrorAlertOpen] = useState(false)
	const [successAlertOpen, setSuccessAlertOpen] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)
	const [showCurrentPassword, setShowCurrentPassword] = useState(false)
	const [changePasswordForm, setChangePasswordForm] = useState({
		password: '',
		confirmPassword: '',
		currentPassword: '',
	})

	const handleOnChange = e => {
		const { name, value } = e.target

		setChangePasswordForm(prevUserData => ({
			...prevUserData,
			[name]: value,
		}))
	}

	const handleChangePassword = async e => {
		e.preventDefault()

		setLoading(true)

		const changePasswordFormData = new FormData()
		changePasswordFormData.append('user_id', localStorage.getItem('user_id'))
		changePasswordFormData.append(
			'current_password',
			changePasswordForm.currentPassword
		)
		changePasswordFormData.append('new_password', changePasswordForm.password)

		const response = await AuthService.changePassword(changePasswordFormData)

		if (!response.error) {
			setLoading(false)
			setSuccessAlertOpen(true)
			setErrorAlertOpen(false)
		} else if (response.error) {
			setLoading(false)
			setError(response.msg)
			setErrorAlertOpen(true)
		} else if (response === undefined) setLoading(false)
	}

	const handleClose = () => {
		setSuccessAlertOpen(false)
		setErrorAlertOpen(false)
	}

	return (
		<>
			{!localStorage.getItem('authenticated') ? (
				<Redirect to='/login' />
			) : (
				<>
					<Box
						id='container'
            maxWidth="80vw"
            
						sx={{
							// margin: {
							// 	lg: '0 2rem',
							// 	md: '0 2rem',
							// 	sm: '0 2rem',
							// 	xs: '0 2rem',
							// },
              margin:"2rem auto"
						}}
					>
						<Box
							id='parent'
							sx={{
								display: 'flex',
								marginTop: { lg: '1rem', md: '1rem', sm: '1rem', xs: '1rem' },

								alignItems: 'stretch',
								borderRadius: '15px',
								// height: { lg: '500px', md: '500px', sm: '500px', xs: '500px',xl:"600px" },
								width: { lg: '100%', md: '100%', sm: '100%', xs: '100%' },
								flexDirection: {
									lg: 'row',
									md: 'row',
									sm: 'column-reverse',
									xs: 'column-reverse',
								},
							}}
						>
							<Box
								id='left'
								sx={{
									background: '#b3e6ff',
                  borderTopLeftRadius: {
                    md: "12px",
                    lg: "12px",
                    sm: '0',
                    xs: '0',
                  },
									 borderBottomLeftRadius: {md:"12px" ,lg: '12px' },
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
									justifyContent: 'center',
									// height: { lg: '470px', md: '500px', sm: '300px', xs: '300px',xl:"300px" },
									width: { lg: '100%', md: '100%', sm: '100%', xs: '100%' },
									 borderTopRightRadius: {
									 	md: 0,
									 	lg: "0",
									 	sm: '0',
									 	xs: '0',
									 },
								}}
							>
								<Box>
									<Typography
										sx={{ mt: 4, mb: 2,
                    textAlign:{sm:"center",md:"center",xs:"center"}
                    }}
                    
										variant='h6'
										component='div'
									>
										{t('passwordMustContainFollowing')}
									</Typography>
									<List>
										<ListItem>
											<ListItemText primary={t('atleast1Uppercase')} />
										</ListItem>
										<ListItem>
											<ListItemText primary={t('atleast1Lowercase')} />
										</ListItem>
										<ListItem>
											<ListItemText primary={t('atleast1Digit')} />
										</ListItem>
										<ListItem>
											<ListItemText primary={t('atleast1SpecialChar')} />
										</ListItem>
									</List>
								</Box>
							</Box>
							<Box
								id='right'
								sx={{
									background: '#e6e6e6',
                  borderBottomRightRadius: {
                    md: "12px",
                    lg:"12px",
                    sm: '0',
                    xs: '0',
                  },

									borderTopRightRadius: { lg: '12px',md:"12px",sm:"12px",xs:"12px" },
                  borderTopLeftRadius: { lg: '0',md:"0",sm:"12px",xs:"12px" },
									width: { lg: '100%', md: '100%', sm: '100%', xs: '100%' },
									// height: { lg: '470px', md: '500px', sm: '300px', xs: '300px',xl:"300px" },
									//
                  
                  borderBottomLeftRadius: {
									 	md: 0,
									 	lg:"0",
									 	sm: '0',
									 	xs: '0',
									 },
								}}
							>
								<Box
									sx={{
										mx: 8,
										// height: { lg: '500px', md: '400px', sm: '400px', xs: '200px',xl:"600px" },
										marginTop: { lg: '8rem' },
									}}
								>
									<Box sx={{
                    textAlign:{md:"center",sm:"center"},
                    marginTop:{md:"1rem",sm:"1rem",xs:"1rem"}
                  }}>
										<Typography
											sx={{ fontSize: '20px' }}
											variant='button'
											display='block'
										>
											{t('changeYourPass')}
										</Typography>
									</Box>
									{/* <Box>
                  <Typography sx={{ fontSize: '12px' }} variant="overline" display="block">
                    Welcome back!! <br />
                  </Typography>
                </Box> */}
									<Box
										sx={{
											// height: { lg: '500px', md: '400px', sm: '400px', xs: '200px',xl:"600px" },
											mt: 3,
										}}
									>
										<form
											style={{ height: '100%', marginBottom: '20%' }}
											onSubmit={handleChangePassword}
										>
											<Box
												sx={{
													textAlign: 'center',
												}}
											>
												<FormControl
													fullWidth
													sx={{ mt: 1 }}
													variant='outlined'
												>
													<InputLabel htmlFor='outlined-adornment-new-password'>
														{t('currentPass')}
													</InputLabel>
													<OutlinedInput
														type={showCurrentPassword ? 'text' : 'password'}
														name='currentPassword'
														variant='outlined'
														onChange={handleOnChange}
														id='outlined-adornment-current-password'
														endAdornment={
															<InputAdornment position='end'>
																<IconButton
																	aria-label='toggle password visibility'
																	onClick={() =>
																		setShowCurrentPassword(!showCurrentPassword)
																	}
																	onMouseDown={e => e.preventDefault()}
																	edge='end'
																>
																	{showCurrentPassword ? (
																		<VisibilityOff />
																	) : (
																		<Visibility />
																	)}
																</IconButton>
															</InputAdornment>
														}
														label={t('currentPass')}
													/>
												</FormControl>
											</Box>

											<Box
												sx={{
													textAlign: 'center',
												}}
											>
												<FormControl
													fullWidth
													sx={{ mt: 1 }}
													variant='outlined'
												>
													<InputLabel htmlFor='outlined-adornment-new-password'>
														{t('newPass')}
													</InputLabel>
													<OutlinedInput
														error={
															!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).+$/.test(
																changePasswordForm.password
															)
														}
														type={showPassword ? 'text' : 'password'}
														name='password'
														variant='outlined'
														onChange={handleOnChange}
														id='outlined-adornment-new-password'
														endAdornment={
															<InputAdornment position='end'>
																<IconButton
																	aria-label='toggle password visibility'
																	onClick={() => setShowPassword(!showPassword)}
																	onMouseDown={e => e.preventDefault()}
																	edge='end'
																>
																	{showPassword ? (
																		<VisibilityOff />
																	) : (
																		<Visibility />
																	)}
																</IconButton>
															</InputAdornment>
														}
														label={t('newPass')}
													/>
												</FormControl>
											</Box>

											<Box
												sx={{
													textAlign: 'center',
												}}
											>
												<FormControl
													fullWidth
													sx={{ mt: 1 }}
													variant='outlined'
												>
													<InputLabel htmlFor='outlined-adornment-confirm-password'>
														{t('confirmNewPass')}
													</InputLabel>
													<OutlinedInput
														error={
															!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).+$/.test(
																changePasswordForm.confirmPassword
															)
														}
														id='outlined-adornment-confirm-password'
														type={showConfirmPassword ? 'text' : 'password'}
														name='confirmPassword'
														onChange={handleOnChange}
														endAdornment={
															<InputAdornment position='end'>
																<IconButton
																	aria-label='toggle password visibility'
																	onClick={() =>
																		setShowConfirmPassword(!showConfirmPassword)
																	}
																	onMouseDown={e => e.preventDefault()}
																	edge='end'
																>
																	{showConfirmPassword ? (
																		<VisibilityOff />
																	) : (
																		<Visibility />
																	)}
																</IconButton>
															</InputAdornment>
														}
														label={t('confirmNewPass')}
													/>
												</FormControl>
											</Box>

											<Box
												sx={{
													textAlign: 'center',
													mt: 2,
												}}
											>
												<LoadingButton
													disabled={
														changePasswordForm.password === '' ||
														changePasswordForm.currentPassword === '' ||
														changePasswordForm.password !==
															changePasswordForm.confirmPassword
													}
													loading={loading}
													loadingPosition='end'
													type='submit'
													variant='contained'
													endIcon={<ChangeCircleIcon />}
												>
													{t('changePass')}
												</LoadingButton>
											</Box>
										</form>
									</Box>
								</Box>
							</Box>
						</Box>
						<SnackBar
							onClose={handleClose}
							severity={successAlertOpen ? 'success' : 'error'}
							message={successAlertOpen ? t('passChangeSuccess') : error}
							alertOpen={successAlertOpen ? successAlertOpen : errorAlertOpen}
						/>
					</Box>
				</>
			)}
		</>
	)
}

export default ChangePassword
