import { Box, Alert, Snackbar, Container } from '@mui/material'
import { Redirect } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import AddPlan from '../../components/plan/AddPlan'
import { GridActionsCellItem } from '@mui/x-data-grid'
import PlanService from '../../api-services/plan-service'
import DataTable from '../../components/dataTable/DataTable'
import EditDialogBox from '../../components/dialog/EditDialogBox'
import DeleteDialogBox from '../../components/dialog/DeleteDialogBox'
import { useTranslation } from 'react-i18next'

const ManagePlans = () => {
	const { t, i18n } = useTranslation()

	const [id, setId] = useState('')
	const [error, setError] = useState('')
	const [plans, setPlans] = useState([])
	const [loading, setLoading] = useState(true)
	const [updating, setUpdating] = useState(false)
	const [alertOpen, setAlertOpen] = useState(false)
	const [openEditDialog, setOpenEditDialog] = useState(false)
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
	const [editPlanForm, setEditPlanForm] = useState({
		plan_id: '',
		plan_name: '',
		duration: '',
		description: '',
		price: '',
		is_trial: false,
	})

	const columns = [
		{ field: 'col1', headerName: t('planName'), width: 200 },
		{ field: 'col2', headerName: t('duration'), width: 100 },
		{ field: 'col3', headerName: t('description'), width: 250 },
		{ field: 'col4', headerName: t('price'), width: 100 },
		{ field: 'col5', headerName: t('trial'), width: 100 },
		{ field: 'col6', headerName: t('status'), width: 100 },
		{
			field: 'actions',
			type: 'actions',
			headerName: t('actions'),
			width: 100,
			getActions: params => [
				<GridActionsCellItem
					icon={<EditIcon color='primary' />}
					label={t('edit')}
					onClick={() => {
						let tempObj = {
							plan_id: params.id,
							price: params.row.col4,
							duration: params.row.col2.replace(' days', ''),
							is_trial: params.row.col5,
							plan_name: params.row.col1,
							description: params.row.col3,
						}

						setEditPlanForm(tempObj)
						setOpenEditDialog(true)
					}}
					showInMenu
				/>,
				<GridActionsCellItem
					icon={<DeleteIcon color='error' />}
					label={t('deactivate')}
					onClick={() => {
						setId(params.id)
						setOpenDeleteDialog(true)
					}}
					showInMenu
				/>,
			],
		},
	]

	const handleOnChange = e => {
		const { name, value } = e.target

		setEditPlanForm(prevPlanData => ({
			...prevPlanData,
			[name]: value,
		}))
	}

	const handleClose = () => {
		setAlertOpen(false)
	}

	const deletePlan = async () => {
		const response = await PlanService.delete(id)

		if (!response.error) {
			const response = await PlanService.list()

			setLoading(false)
			if (!response.error) {
				let tempArr = []

				if (response.data !== undefined) {
					for (let i = 0; i < response.data.length; i++) {
						tempArr.push({
							id: response.data[i].id,
							col1: response.data[i].plan_name,
							col2:
								response.data[i].duration !== 'MONTH' &&
								response.data[i].duration !== 'YEAR'
									? response.data[i].duration + ' ' + 'days'
									: response.data[i].duration,
							col3: response.data[i].description,
							col4: response.data[i].price,
							col5: response.data[i].is_trial,
							col6: response.data[i].status,
						})
					}
					setPlans(tempArr)
					setOpenDeleteDialog(false)
				}
			}
		}
	}

	const handleEditPlan = async () => {
		setUpdating(true)

		const editPlanFormData = new FormData()

		editPlanFormData.append('plan_id', editPlanForm.plan_id)
		editPlanFormData.append('plan_name', editPlanForm.plan_name)
		editPlanFormData.append('duration', editPlanForm.duration)
		editPlanFormData.append('description', editPlanForm.description)
		editPlanFormData.append('price', editPlanForm.price)
		editPlanFormData.append('is_trial', editPlanForm.is_trial)

		const response = await PlanService.update(editPlanFormData)

		if (!response.error) {
			const response = await PlanService.list()

			setUpdating(false)
			if (!response.error) {
				let tempArr = []

				if (response.data !== undefined) {
					for (let i = 0; i < response.data.length; i++) {
						tempArr.push({
							id: response.data[i].id,
							col1: response.data[i].plan_name,
							col2:
								response.data[i].duration !== 'MONTH' &&
								response.data[i].duration !== 'YEAR'
									? response.data[i].duration + ' ' + 'days'
									: response.data[i].duration,
							col3: response.data[i].description,
							col4: response.data[i].price,
							col5: response.data[i].is_trial,
							col6: response.data[i].status,
						})
					}
					setPlans(tempArr)
					setOpenEditDialog(false)
				}
			} else {
				setUpdating(false)

				setError(response.msg)
				setAlertOpen(true)
			}
		}
	}

	const fetchPlanList = async () => {
		const response = await PlanService.list()

		if (!response.error) {
			setLoading(false)

			let tempArr = []

			if (response.data !== undefined) {
				for (let i = 0; i < response.data.length; i++) {
					tempArr.push({
						id: response.data[i].id,
						col1: response.data[i].plan_name,
						col2:
							response.data[i].duration !== 'MONTH' &&
							response.data[i].duration !== 'YEAR'
								? response.data[i].duration + ' ' + 'days'
								: response.data[i].duration,
						col3: response.data[i].description,
						col4: response.data[i].price,
						col5: response.data[i].is_trial,
						col6: response.data[i].status,
					})
				}
				setPlans(tempArr)
			}
		} else {
			setLoading(false)
			setError('Something went wrong...')
			setAlertOpen(true)
		}
	}

	useEffect(() => {
		fetchPlanList()
	}, [])

	return (
		<>
			{localStorage.getItem('role') === 'admin' ? (
				<>
					<Container
						maxWidth='xl'
						sx={{
							display: 'flex',
							flexDirection: {
								lg: 'row',
								md: 'row',
								sm: 'column',
								xs: 'column',
							},
						}}
					>
						<Box
							sx={{
								display: 'flex',
								marginTop: '2%',
								borderRadius: '15px',
								width: { lg: '70vw', md: '60vw', sm: '100%' },
								height: { lg: '80vh', md: '70vh', sm: '70vh', xs: '70vh' },
								flexDirection: {
									lg: 'column',
									md: 'column',
									sm: 'column',
									xs: 'column',
								},
							}}
						>
							<DataTable rows={plans} columns={columns} loading={loading} />
						</Box>
						<Box
							sx={{
								display: 'flex',
								marginTop: '3%',
								alignItems: 'center',
								borderRadius: '15px',
								padding: { lg: '10px', md: '10px' },
								width: { lg: '30vw', md: '40vw', sm: '100%' },
								height: { lg: '70vh', md: '70vh', sm: '70vh', xs: '70vh' },
								flexDirection: {
									lg: 'row',
									md: 'row',
									sm: 'column',
									xs: 'column',
								},
							}}
						>
							<AddPlan setPlans={setPlans} />
						</Box>
					</Container>
					<Snackbar
						open={alertOpen}
						onClose={handleClose}
						autoHideDuration={3000}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					>
						<Alert
							onClose={handleClose}
							sx={{ width: '100%' }}
							severity={error === '' ? 'success' : 'error'}
						>
							{error === '' ? 'User Added Successfully' : error}
						</Alert>
					</Snackbar>
					<DeleteDialogBox
						usageFlag='plan'
						open={openDeleteDialog}
						deletePlan={deletePlan}
						handleClose={() => setOpenDeleteDialog(false)}
					/>
					<EditDialogBox
						updating={updating}
						open={openEditDialog}
						editPlan={handleEditPlan}
						editPlanForm={editPlanForm}
						handleOnChange={handleOnChange}
						handleClose={() => setOpenEditDialog(false)}
					/>
				</>
			) : (
				<Redirect to='/' />
			)}
		</>
	)
}

export default ManagePlans
