import {
	Alert,
	Snackbar,
	Typography,
	ToggleButton,
	LinearProgress,
} from '@mui/material'
import QRCode from 'qrcode'
import { Box } from '@mui/system'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import AuthService from '../../api-services/auth-service'
import UserService from '../../api-services/user-service'

const Activate2FA = () => {
	const { t, i18n } = useTranslation()

	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [selected, setSelected] = useState(false)
	const [alertOpen, setAlertOpen] = useState(false)

	const handleClose = () => {
		setAlertOpen(false)
	}

	const fetchData = async () => {
		setLoading(true)

		const response = await UserService.detail(localStorage.getItem('user_id'))

		if (!response.error) {
			setLoading(false)
			console.log(
				`otpauth://totp/IdentitySafe:${localStorage.getItem(
					'email'
				)}?secret=JBSWY3DPEHPK3PXP&issuer=IdentitySafe`
			)
			QRCode.toDataURL(
				`otpauth://totp/IdentitySafe:${localStorage.getItem(
					'email'
				)}?secret=JBSWY3DPEHPK3PXP&issuer=IdentitySafe`,
				function (err, url) {
					if (err) throw err

					var img = document.getElementById('image')
					if (response.data.auth_enabled) img.src = url
				}
			)

			setSelected(response.data.auth_enabled)
		} else {
			setLoading(false)
			setError(response.error)
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<>
			{!localStorage.getItem('authenticated') ? (
				<Redirect to='/dashboard' />
			) : loading ? (
				<Box
					sx={{
						width: '90%',
						// minHeigh:"70vh",
						marginTop: '2rem',
						marginLeft: { lg: '5%', md: '5%', sm: '5%', xs: '5%' },
						overflowX: 'hidden',
						overflowY: 'hidden',
						scrollbarWidth: 'none',
					}}
				>
					<LinearProgress
						sx={{
							overflowX: 'hidden',
							overflowY: 'hidden',
							scrollbarWidth: 'none',
						}}
					/>
				</Box>
			) : (
				<>
					<Box
						sx={{
							marginLeft: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
							marginRight: { lg: '20%', md: '20%', sm: '20%', xs: '5%' },
						}}
					>
						<Box
							sx={{
								display: 'flex',
								marginTop: '8%',
								alignItems: 'center',
								borderRadius: '15px',
								height: { lg: '70vh', md: '80vh', sm: '80vh', xs: '80vh' },
								flexDirection: {
									lg: 'row',
									md: 'column',
									sm: 'column',
									xs: 'column',
								},
							}}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									background: '#b3e6ff',
									flexDirection: 'column',
									justifyContent: 'center',
									borderTopLeftRadius: '12px',
									borderBottomLeftRadius: { lg: '12px' },
									height: { lg: '100%', md: '45%', sm: '30%', xs: '30%' },
									width: { lg: '55%', md: '100%', sm: '100%', xs: '100%' },
									borderTopRightRadius: {
										md: '12px',
										lg: 0,
										sm: '12px',
										xs: '12px',
									},
								}}
							>
								<ToggleButton
									sx={{
										marginTop: { lg: 0, md: '3rem', sm: '3rem', xs: '3rem' },
										textAlign: 'center',
									}}
									value='check'
									selected={selected}
									onChange={async () => {
										const response = await AuthService.enableTwoFactorAuth()

										if (!response.error) {
											if (response.data.auth_enabled) {
												QRCode.toDataURL(
													`otpauth://totp/${localStorage.getItem(
														'email'
													)}?secret=JBSWY3DPEHPK3PXP&issuer=IdentitySafe`,
													function (err, url) {
														if (err) throw err

														var img = document.getElementById('image')
														img.src = url

														localStorage.setItem('authEnabled', true)

														setSelected(!selected)
													}
												)
											} else {
												var img = document.getElementById('image')
												img.src = ''
												localStorage.setItem('authEnabled', false)

												setSelected(!selected)
											}
										} else {
											setError(response.msg)
											setAlertOpen(true)
										}
									}}
								>
									{selected ? (
										<ToggleOnIcon fontSize='large' />
									) : (
										<ToggleOffIcon fontSize='large' />
									)}
								</ToggleButton>

								<Typography
									sx={{
										mt: '1rem',
										mb: 4,
										fontSize: {
											lg: '20px',
											md: '16px',
											sm: '14px',
											xs: '10px',
										},
									}}
									variant='h6'
									component='div'
								>
									{selected ? t('scanCodeWithApp') : t('activate2fa')}
								</Typography>
							</Box>
							<Box
								sx={{
									background: '#e6e6e6',
									borderBottomRightRadius: '12px',
									height: { lg: '100%', md: '50%', sm: '50%', xs: '50%' },
									borderTopRightRadius: { lg: '12px' },
									width: { lg: '45%', md: '100%', sm: '100%', xs: '100%' },
									borderBottomLeftRadius: {
										md: '12px',
										lg: 0,
										sm: '12px',
										xs: '12px',
									},
								}}
							>
								<Box
									sx={{
										mx: 8,
										height: '55%',
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
										justifyContent: 'center',
										marginTop: {
											lg: '20%',
											md: '2rem',
											sm: '2rem',
											xs: '2rem',
										},
									}}
								>
									<img
										style={{ visibility: selected ? 'visible' : 'hidden' }}
										id='image'
										height='100%'
										src=''
										alt='QR Code'
									/>
								</Box>
							</Box>
						</Box>
						<Snackbar
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
							open={alertOpen}
							autoHideDuration={6000}
							onClose={handleClose}
						>
							<Alert
								onClose={handleClose}
								severity='error'
								sx={{ width: '100%' }}
							>
								{error}
							</Alert>
						</Snackbar>
					</Box>
				</>
			)}
		</>
	)
}

export default Activate2FA
