import {
	Alert,
	Snackbar,
	AlertTitle,
	IconButton,
	Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AddLinkIcon from '@mui/icons-material/AddLink'
import EncryptionService from '../api-services/enc-service'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const GenerateURL = () => {
	const { t, i18n } = useTranslation()

	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [alertOpen, setAlertOpen] = useState(false)
	const [responseURL, setResponseURL] = useState('')

	const handleEncryptionClick = async () => {
		setLoading(true)

		const encryptionFormData = new FormData()
		encryptionFormData.append('user_id', localStorage.getItem('user_id'))

		const response = await EncryptionService.generateURL(encryptionFormData)

		if (!response.error) {
			setLoading(false)
			setResponseURL(response.url)
		} else {
			setLoading(false)
			setError(response.msg)
			setAlertOpen(true)
		}

		setLoading(false)
	}

	return localStorage.getItem('plan_activated') !== null ||
		localStorage.getItem('plan_activated') === 'true' ? (
		<Box
			sx={{
				display: 'flex',
				marginTop: '5%',
				alignItems: 'center',
				borderRadius: '15px',
				height: { lg: '70vh', md: '70vh', sm: '70vh', xs: '70vh' },
				flexDirection: { lg: 'row', md: 'column', sm: 'column', xs: 'column' },
			}}
		>
			<Box
				sx={{
					textAlign: 'center',
					background: '#b3e6ff',
					borderTopLeftRadius: '12px',
					borderBottomLeftRadius: { lg: '12px' },
					width: { lg: '60%', md: '100%', sm: '100%', xs: '100%' },
					borderTopRightRadius: { lg: 0, md: '12px', sm: '12px', xs: '12px' },
					height: { lg: '100%', md: '50%', sm: '50%', xs: '100%', xl: '100%' },
				}}
			>
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						textAlign: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						justifyContent: 'center',
						borderTopLeftRadius: '12px',
						borderBottomLeftRadius: '12px',
					}}
				>
					<Typography
						display='block'
						variant='body1'
						sx={{
							lineHeight: '1.4em',
							fontSize: { lg: '20px', md: '20px', sm: '15px', xs: '15px' },
						}}
					>
						{responseURL !== ''
							? t('copyLinkAndSend')
							: t('clickToGenerateURL')}
					</Typography>
					{responseURL === '' ? (
						<></>
					) : (
						<Box
							sx={{
								display: 'flex',
								textAlign: 'center',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Typography
								display='block'
								variant='subtitle2'
								sx={{
									overflow: { md: 'hidden', sm: 'hidden', xs: 'hidden' },
									whiteSpace: { md: 'nowrap', sm: 'nowrap', xs: 'nowrap' },
									fontSize: { lg: '20px', md: '20px', sm: '15px', xs: '15px' },
									width: {
										lg: '100%',
										xl: '100%',
										sm: '400px',
										md: '700px',
										xs: '150px',
									},
									textOverflow: {
										sm: 'ellipsis',
										md: 'ellipsis',
										xs: 'ellipsis',
									},
								}}
							>
								<p
									id='responseLink'
									style={{ margin: 0 }}
									data-link={responseURL}
								>
									{responseURL}
								</p>
							</Typography>
							<IconButton
								edge='end'
								id='copyButton'
								data-clipboard-target='#responseLink'
							>
								{<ContentCopyIcon />}
							</IconButton>
						</Box>
					)}
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					background: '#e6e6e6',
					flexDirection: 'column',
					borderBottomLeftRadius: {
						lg: '12px',
						md: '12px',
						sm: '12px',
						xs: '12px',
					},
					borderBottomRightRadius: '12px',
					borderTopRightRadius: { lg: '12px' },
					height: { lg: '100%', md: '70%', sm: '70%', xs: '80%' },
					width: { lg: '40%', md: '100%', sm: '100%', xs: '100%' },
				}}
			>
				<Box
					sx={{
						width: '100%',
						height: '100%',
					}}
				>
					<Box
						sx={{
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Box
							sx={{
								textAlign: 'center',
								mt: { lg: 2, md: 2, sm: 2, xs: 2 },
							}}
						>
							<LoadingButton
								loading={loading}
								variant='contained'
								loadingPosition='end'
								endIcon={<AddLinkIcon />}
								onClick={handleEncryptionClick}
							>
								{responseURL === ''
									? t('generateURL')
									: t('generateAnotherURL')}
							</LoadingButton>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						width: { lg: '100%' },
					}}
				>
					<Alert
						sx={{
							fontSize: { xs: '13px' },
							borderBottomLeftRadius: {
								lg: 0,
								md: '12px',
								sm: '12px',
								xs: '12px',
							},
							borderBottomRightRadius: '12px',
						}}
						severity='warning'
					>
						<AlertTitle>{t('warning')}</AlertTitle>
						{t('dataAvailableAfer1TimeUseGenerateURL')}
					</Alert>
				</Box>

				<Snackbar
					open={alertOpen}
					autoHideDuration={3000}
					onClose={() => setAlertOpen(false)}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert
						sx={{ width: '100%' }}
						onClose={() => setAlertOpen(false)}
						severity={error === '' ? 'success' : 'error'}
					>
						{error === '' ? t('userAddSuccess') : error}
					</Alert>
				</Snackbar>
			</Box>
		</Box>
	) : (
		<Redirect to={{ pathname: '/plans' }} />
	)
}

export default GenerateURL
