import {
	Box,
	Alert,
	Select,
	MenuItem,
	Snackbar,
	Typography,
	InputLabel,
	FormControl,
	OutlinedInput,
} from '@mui/material'
import React, { useState } from 'react'
import Radio from '@mui/material/Radio'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import PlanService from '../../api-services/plan-service'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTranslation } from 'react-i18next'

const AddPlan = ({ setPlans }) => {
	const { t, i18n } = useTranslation()

	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [alertOpen, setAlertOpen] = useState(false)
	const [addPlanForm, setAddPlanForm] = useState({
		plan_name: '',
		duration: '',
		description: '',
		price: '',
		is_trial: 'false',
	})

	const handleOnChange = e => {
		const { name, value } = e.target

		setAddPlanForm(prevPlanData => ({
			...prevPlanData,
			[name]: value,
		}))
	}

	const handleAddPlan = async e => {
		e.preventDefault()

		setLoading(true)

		const planFormData = new FormData()
		planFormData.append('price', addPlanForm.price)
		planFormData.append('duration', addPlanForm.duration)
		planFormData.append('is_trial', addPlanForm.is_trial)
		planFormData.append('plan_name', addPlanForm.plan_name)
		planFormData.append('description', addPlanForm.description)

		const response = await PlanService.add(planFormData)

		if (!response.error) {
			setLoading(false)
			setAlertOpen(true)

			const planServiceResponse = await PlanService.list()

			setLoading(false)
			if (!planServiceResponse.error) {
				let tempArr = []

				if (planServiceResponse.data !== undefined) {
					for (let i = 0; i < planServiceResponse.data.length; i++) {
						tempArr.push({
							id: planServiceResponse.data[i].id,
							col1: planServiceResponse.data[i].plan_name,
							col2: planServiceResponse.data[i].duration,
							col3: planServiceResponse.data[i].description,
							col4: '€ ' + planServiceResponse.data[i].price,
							col5: planServiceResponse.data[i].trial,
							col6: planServiceResponse.data[i].status,
						})
					}
					setPlans(tempArr)
				}
			} else {
				setError('Something went wrong...')
				setAlertOpen(true)
			}
		} else if (response.error) {
			setLoading(false)
			setError(response.msg)
			setAlertOpen(true)
		} else if (response === undefined) setLoading(false)
	}

	const handleClose = () => {
		setAlertOpen(false)
	}

	return (
		<>
			<Box
				sx={{
					height: '70%',
					width: '100%',
					mx: { lg: 4, md: 4 },
					// marginTop: { lg: "25%" }
				}}
			>
				<Box>
					<Typography
						sx={{ fontSize: '15px' }}
						variant='overline'
						display='block'
					>
						{t('addNewPlan')}
						<br />
					</Typography>
				</Box>
				<form onSubmit={handleAddPlan}>
					<Box
						sx={{
							textAlign: 'center',
						}}
					>
						<FormControl fullWidth sx={{ mt: 1 }} variant='outlined'>
							<InputLabel htmlFor='outlined-adornment-planeName'>
								{t('planName')}
							</InputLabel>
							<OutlinedInput
								type='text'
								name='plan_name'
								label={t('planName')}
								variant='outlined'
								onChange={handleOnChange}
								id='outlined-adornment-planeName'
							/>
						</FormControl>
					</Box>
					<Box
						sx={{
							textAlign: 'center',
						}}
					>
						<FormControl fullWidth sx={{ mt: 1 }}>
							<InputLabel id='outlined-adornment-duration'>
								{t('duration')}
							</InputLabel>
							{addPlanForm.is_trial === 'true' ? (
								<OutlinedInput
									inputProps={{
										min: '1',
									}}
									type='number'
									name='duration'
									label={t('duration')}
									variant='outlined'
									onChange={handleOnChange}
									id='outlined-adornment-duration'
								/>
							) : (
								<Select
									name='duration'
									label={t('duration')}
									onChange={handleOnChange}
									value={addPlanForm.duration}
									id='outlined-adornment-duration'
									labelId='demo-simple-select-label'
								>
									<MenuItem value='MONTH'>{t('month')}</MenuItem>
									<MenuItem value='YEAR'>{t('year')}</MenuItem>
								</Select>
							)}
						</FormControl>
					</Box>

					<Box
						sx={{
							textAlign: 'center',
						}}
					>
						<FormControl fullWidth sx={{ mt: 1 }}>
							<InputLabel htmlFor='outlined-adornment-description'>
								{t('description')}
							</InputLabel>
							<OutlinedInput
								multiline
								type='text'
								minRows={4}
								name='description'
								variant='outlined'
								label={t('description')}
								onChange={handleOnChange}
								id='outlined-adornment-description'
							/>
						</FormControl>
					</Box>

					<Box
						sx={{
							textAlign: 'center',
						}}
					>
						<FormControl fullWidth sx={{ mt: 1 }}>
							<InputLabel htmlFor='outlined-adornment-price'>
								{t('price')} (€)
							</InputLabel>
							<OutlinedInput
								inputProps={{
									min: '0',
								}}
								name='price'
								type='number'
								label={t('price') + '(€)'}
								variant='outlined'
								onChange={handleOnChange}
								id='outlined-adornment-price'
							/>
						</FormControl>
					</Box>

					<Box
						sx={{
							marginTop: '10px',
							textAlign: 'center',
						}}
					>
						<FormControl>
							<FormLabel id='demo-radio-buttons-group-label'>
								{t('trial')}
							</FormLabel>
							<RadioGroup
								name='is_trial'
								defaultValue='false'
								onChange={handleOnChange}
								aria-labelledby='demo-radio-buttons-group-label'
							>
								<FormControlLabel
									value='true'
									control={<Radio />}
									label={t('yes')}
								/>
								<FormControlLabel
									value='false'
									control={<Radio />}
									label={t('no')}
								/>
							</RadioGroup>
						</FormControl>
					</Box>

					<Box
						sx={{
							mt: 2,
							textAlign: 'center',
						}}
					>
						<LoadingButton
							type='submit'
							loading={loading}
							variant='contained'
							loadingPosition='start'
							startIcon={<AddIcon />}
							disabled={
								addPlanForm.plan_name === '' ||
								addPlanForm.duration === '' ||
								addPlanForm.description === '' ||
								addPlanForm.price === ''
							}
						>
							{t('addPlan')}
						</LoadingButton>
					</Box>
				</form>
			</Box>
			<Snackbar
				open={alertOpen}
				onClose={handleClose}
				autoHideDuration={3000}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert
					onClose={handleClose}
					sx={{ width: '100%' }}
					severity={error === '' ? 'success' : 'error'}
				>
					{error === '' ? t('planAddSuccess') : error}
				</Alert>
			</Snackbar>
		</>
	)
}

export default AddPlan
