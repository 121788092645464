import {
	Alert,
	Snackbar,
	Container,
	Typography,
	AlertTitle,
	LinearProgress,
} from '@mui/material'
import { Box } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LoginIcon from '@mui/icons-material/Login'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import PreviewIcon from '@mui/icons-material/Preview'
import EncryptionService from '../../api-services/enc-service'

const Decrypt = () => {
	const { id } = useParams()
	const history = useHistory()
	const { t, i18n } = useTranslation()

	const [error, setError] = useState('')
	const [hasData, setHasData] = useState(false)
	const [loading, setLoading] = useState(false)
	// const [redirect, setRedirect] = useState(false)
	const [alertOpen, setAlertOpen] = useState(false)
	const [responseText, setResponseText] = useState('')
	const [responseFile, setResponseFile] = useState('')
	const [responseType, setResponseType] = useState('')
	const [downloading, setDownloading] = useState(false)
	const [redirectCheck, setRedirectCheck] = useState(true)

	const handleDecryptionClick = async () => {
		setLoading(true)

		const decryptionFormData = new FormData()

		decryptionFormData.append('id', id)
		decryptionFormData.append('user_id', localStorage.getItem('user_id'))
		// decryptionFormData.append('token', localStorage.getItem('token'))

		const response = await EncryptionService.decryptData(decryptionFormData)

		if (!response.error) {
			setLoading(false)
			if (response.data.is_file === 'True') {
				setResponseFile(response.data.text)
				setResponseType('file')
			}
			if (response.data.is_file === 'False') {
				setResponseType('text')
				setResponseText(response.data.text)
			}
			if (response.data.is_file === '') {
				setResponseType('dataRemoved')
				setResponseText(response.data.text)
			}
			if (response.data.is_file === 'encrypt') {
				setResponseType('encrypt')
				localStorage.setItem('id', id)
				// setRedirect(true)
			}
		} else {
			setLoading(false)
			setError(response.msg)
			setAlertOpen(true)
		}
	}

	const handleClose = () => {
		setAlertOpen(false)
	}

	const fetchDataExistence = async () => {
		let dataExistenceFormData = new FormData()

		dataExistenceFormData.append('id', id)
		dataExistenceFormData.append(
			'token',
			'60eb277cb2ad083feac96c24a5dcbfc1cb3a92503e219197ca727e124a802784'
		)

		const response = await EncryptionService.hasData(dataExistenceFormData)

		if (!response.error) {
			if (response.has_data) {
				setHasData(true)
				setRedirectCheck(false)
			} else {
				setHasData(false)
				setRedirectCheck(false)
				localStorage.setItem('id', id)
				history.replace('/guest-dashboard')
			}
		}
	}

	const getFile = async () => {
		setDownloading(true)
		const response = await EncryptionService.getFile(responseFile)

		if (!response.error) {
			const file = URL.createObjectURL(response)

			const link = document.createElement('a')
			link.href = file
			link.setAttribute('download', 'file')
			document.body.appendChild(link)
			link.click()
			setDownloading(false)

			const deleteResponse = await EncryptionService.deleteFile(responseFile)

			if (deleteResponse.error) {
				setError(deleteResponse.message)
				setAlertOpen(true)
			}
		}
	}

	useEffect(() => {
		if (!localStorage.getItem('token') && !id !== 'dashboard') {
			localStorage.setItem('id', id)
			setRedirectCheck(false)
			history.push('/')
		} else fetchDataExistence()
	}, [])

	useEffect(() => {
		if (responseFile !== '') {
			getFile()
		}
	}, [responseFile])

	return (
		<>
			{redirectCheck && !localStorage.getItem('authenticated') ? (
				<Redirect to='/guest-dashboard' />
			) : hasData && !localStorage.getItem('token') ? (
				<Redirect to='/login' />
			) : (
				// :
				// redirectCheck
				//   ? <LinearProgress />
				<Container maxWidth='lg'>
					<Box
						sx={{
							overflow: 'hidden',
							display: 'flex',
							alignItems: 'center',
							borderRadius: '15px',
							mt: { lg: 12, md: 12, sm: 12, xs: 12 },
							height: { lg: '70vh', md: '70vh', sm: '70vh', xs: '70vh' },
							flexDirection: {
								lg: 'row',
								md: 'column',
								sm: 'column',
								xs: 'column',
							},
						}}
					>
						<Box
							sx={{
								background: '#b3e6ff',

								borderBottomLeftRadius: {
									lg: '12px',
									md:
										responseType === 'file' || responseType === '' ? 0 : '12px',
									sm:
										responseType === 'file' || responseType === '' ? 0 : '12px',
									xs:
										responseType === 'file' || responseType === '' ? 0 : '12px',
								},
								width: {
									lg:
										responseType === 'file' || responseType === ''
											? '60%'
											: '100%',
									md: '70%',
									sm: '90%',
									xs: '100%',
								},
								height: {
									lg: '100%',
									md:
										responseType === 'file' || responseType === ''
											? '25%'
											: '100%',
									sm:
										responseType === 'file' || responseType === ''
											? '25%'
											: '100%',
									xs:
										responseType === 'file' || responseType === ''
											? '25%'
											: '100%',
								},
								borderTopRightRadius: {
									lg:
										responseType === 'file' || responseType === '' ? 0 : '12px',
									md: '12px',
									sm: '12px',
									xs: '12px',
								},
								borderBottomRightRadius: {
									lg:
										responseType === 'file' || responseType === '' ? 0 : '12px',
									md:
										responseType === 'file' || responseType === '' ? 0 : '12px',
									sm:
										responseType === 'file' || responseType === '' ? 0 : '12px',
									xs:
										responseType === 'file' || responseType === '' ? 0 : '12px',
								},
								borderTopLeftRadius: {
									lg: '12px',
									md: '12px',
									sm: '12px',
									xs: '12px',
								},
							}}
						>
							{responseText === '' && responseFile === '' ? (
								<Box
									sx={{
										height: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Typography
										display='block'
										variant='overline'
										sx={{
											fontSize: {
												xs: '12px',
												lg: '20px',
												md: '20px',
												sm: '18px',
											},
											lineHeight: '1.4em',
											textAlign: 'center',
										}}
									>
										{t('clickToViewData')}
									</Typography>
								</Box>
							) : (
								<Box
									sx={{
										height: '100%',
										display: 'flex',
										alignItems: 'center',

										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>
									{responseText === 'Data is removed' ? (
										<></>
									) : (
										<Box>
											<Typography
												sx={{ fontSize: '20px' }}
												variant='overline'
												display='block'
											>
												{t('yourDecryptedData')}
												<br />
												<Typography
													sx={{ fontSize: '20px', textAlign: 'center' }}
													variant='overline'
													display='block'
												>
													{t('isNowReady')}
												</Typography>
											</Typography>
										</Box>
									)}
									<Box
										sx={{
											textAlign: 'center',

											height:
												responseType === 'file' || responseType === ''
													? ''
													: '85%',
											width:
												responseType === 'file' || responseType === ''
													? ''
													: '95%',
											overflowY:
												responseType === 'file' || responseType === ''
													? 'hidden'
													: 'auto',
										}}
									>
										<Typography
											display='block'
											variant='subtitle2'
											sx={{ fontSize: '20px' }}
										>
											<pre>{responseText}</pre>
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
						{responseType === 'file' || responseType === '' ? (
							<Box
								sx={{
									background: '#e6e6e6',
									borderBottomRightRadius: '12px',
									borderTopRightRadius: { lg: '12px', md: 0 },
									width: { lg: '40%', md: '70%', sm: '90%', xs: '100%' },
									height: { lg: '100%', md: '40%', sm: '35%', xs: '25%' },
									borderBottomLeftRadius: {
										lg: 0,
										md: '12px',
										sm: '12px',
										xs: '12px',
									},
									overflowY: 'hidden',
								}}
							>
								<Box
									sx={{
										width: '100%',
										height: '100%',
									}}
								>
									<Box
										sx={{
											height: '100%',
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										{responseType !== 'file' ? (
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													flexDirection: 'column',
													justifyContent: 'center',
													overflowY: 'hidden',
												}}
											>
												<Box
													sx={{
														mt: { lg: 2, md: 0 },
													}}
												>
													<LoadingButton
														loading={loading || downloading}
														variant='contained'
														loadingPosition='end'
														endIcon={
															responseType === 'file' ? (
																<></>
															) : responseType === 'text' ? (
																<PreviewIcon />
															) : responseType === 'encrypt' ? (
																<LoginIcon />
															) : (
																<PreviewIcon />
															)
														}
														onClick={handleDecryptionClick}
													>
														{responseType === 'file' ? (
															<span>{t('downloadFile')}</span>
														) : responseType === 'text' ? (
															<span>{t('viewData')}</span>
														) : responseType === 'encrypt' ? (
															<span>{t('generateLink')}</span>
														) : (
															<span>{t('getData')}</span>
														)}
													</LoadingButton>
												</Box>
											</Box>
										) : downloading ? (
											<Box
												sx={{
													width: '90%',
												}}
											>
												<LinearProgress />
											</Box>
										) : (
											<Box
												sx={{
													width: '100%',
												}}
											>
												<Alert
													sx={{
														borderRadius: '0px',
													}}
													severity='warning'
												>
													<AlertTitle>{t('warning')}</AlertTitle>
													{t('yourDataIsWipedFromOurSystems')}
												</Alert>
											</Box>
										)}
									</Box>
								</Box>
							</Box>
						) : (
							<></>
						)}
					</Box>
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						open={alertOpen}
						autoHideDuration={6000}
						onClose={handleClose}
					>
						<Alert
							onClose={handleClose}
							severity='error'
							sx={{ width: '100%' }}
						>
							{error}
						</Alert>
					</Snackbar>
				</Container>
			)}
		</>
	)
}

export default Decrypt
